import React from 'react';
import { Link } from 'react-router-dom'

export default function Annual_reports(){
  return (
     <div>
     {/* Header Start */}
	  <div className="container-fluid hero-header bg-light py5 mb-5"  style={{"padding-top":"1rem !important","padding-bottom":"1rem !important"}}>
		<div className="container py-5">
		  <div className="row g-5 align-items-center">
			<div className="col-lg-12">
			  <h1 className="display-4 mb3 animated slideInDown">Annual Report</h1>
			  <nav aria-label="breadcrumb animated slideInDown">
				<ol className="breadcrumb mb-0">
				  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
				  <li className="breadcrumb-item"><Link to="/">Investor Relation</Link></li>
				  <li className="breadcrumb-item active" aria-current="page">Annual Report</li>
				</ol>
			  </nav>
			</div>
			
			
		  </div>
		</div>
	  </div>
	  {/* Header End */}
	  {/* About Start */}
	  <div className="container-xxl py5">
		<div className="container">
		  <div className="row g-5 align-items-center">
			<div className="table-responsive table_heading">
				  <table className="table table-bordered table-striped">
					<thead>
					  <tr>
						{/*<th width="20" class="text-center">No.</th>*/}
						<th className="text-left">Report Name</th>
						<th>Download Link</th>
					  </tr>
					</thead>
					<tbody>
					  <tr>
						<td>Annual Report 2023-24</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-2024.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  <tr>
						<td>Annual Report 2022-23</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-FY-2022-23.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					  
					  <tr>
						<td>Annual Report 2021-22</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-2021-22.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr> 

					  <tr>
						<td>Annual Report 2020-21</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-2020-2021.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr> 
					  
					  <tr>
						<td>Annual Report 2019-20</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-2019-2020.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>

					  <tr>
						<td>Annual Report 2017-18</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/ANNUAL-REPORT-2017-18.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					   <tr>
						<td>Annual Report 2016-17</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-2016-2017.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					  
					   <tr>
						<td>Annual Report 2015-16</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-Report-2015-2016.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					  
					   <tr>
						<td>Annual Report 2014-15</td>
						<td width={280} className="text-center">
						 <Link to="/download/Financial_Results/Annual-REport-2014-2015.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					 
					</tbody>
				  </table>
				</div>

			
		  </div>
		</div>
	  </div>
	  {/* About End */}
	</div>
    );
}