import React from "react";
//import About from "./page/about/About";
//import Contact from "./page/contact/contact";
import { BrowserRouter as Router ,Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./page/Home";
import Shareholding from "./page/Shareholding";
import Annualsecretaria from "./page/Annualsecretaria";
import Corporate from "./page/Corporate";
import Financial from "./page/Financial";
import Committees from "./page/Committees";
import Boardofdirector from "./page/Boardofdirector";
import Annual_reports from "./page/Annual_reports";
import About from "./page/About";
import Investor from "./page/Investor";
import Policies from "./page/Policies";
import Contact from "./page/Contact";


function App() {
  return (
    <div>
	<Router>
      <NavBar />
	   
	    <Routes>
		  <Route path="/" element={<Home />} />
		  <Route path="/about" element={<About />} />
		  <Route path="/investor-relation" element={<Investor />} />
		  <Route path="/annual-secretarial-compliance-report" element={<Annualsecretaria />} />
		  <Route path="/corporate-governance" element={<Corporate />} />
		  <Route path="/financial-result" element={<Financial />} />
		  <Route path="/committees-of-board" element={<Committees />} />
		  <Route path="/board-of-director" element={<Boardofdirector />} />
		  <Route path="/annual-reports" element={<Annual_reports />} />
		  <Route path="/shareholding-pattern" element={<Shareholding />} />
		  <Route path="/codes-policies" element={<Policies />} />
		  <Route path="/contact" element={<Contact />} />
		</Routes>


	
      <Footer />
	 </Router>

    </div>
  );
}

export default App;