import React from 'react';
import { Link } from 'react-router-dom'

export default function Boardofdirector(){
  return (
     <div>
     {/* Header Start */}
	  <div className="container-fluid hero-header bg-light py5 mb-5"  style={{"padding-top":"1rem !important","padding-bottom":"1rem !important"}}>
		<div className="container py-5">
		  <div className="row g-5 align-items-center">
			<div className="col-lg-12">
			  <h1 className="display-4 mb3 animated slideInDown">Board Of Director</h1>
			  <nav aria-label="breadcrumb animated slideInDown">
				<ol className="breadcrumb mb-0">
				  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
				  <li className="breadcrumb-item"><Link to="/">Investor Relation</Link></li>
				  <li className="breadcrumb-item active" aria-current="page">Board Of Director</li>
				</ol>
			  </nav>
			</div>
			
			
		  </div>
		</div>
	  </div>
	  {/* Header End */}
	  {/* About Start */}
	  <div className="container-xxl py5">
		<div className="container">
		 <div className="content-column col-lg-12 col-md-12 col-sm-12">
			  <div className="row">
				<h3>List of Directors of KALPA Commercial Limited</h3>
				<aside className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
					  <div className="table-responsive table_heading">
						<table className="table table-bordered table-striped">
						  <thead>
							<tr>
							  <th className="text-left">Name</th>
							  <th>Designation </th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  {/*<td class="text-center">1</td>*/}
							  <td>Mr. ISHANT MALHOTRA</td>
							  <td width={280}>Managing Director </td>
							</tr>
							<tr>
							  {/*<td class="text-center">2</td>*/}
							  <td>Ms. Shivani</td>
							  <td width={280}>Non-Executive-Independent Director</td>
							</tr>
							<tr>
							  {/*<td class="text-center">3</td>*/}
							  <td>Mr. Love Kumar </td>
							  <td width={280}>Non-Executive-Independent  Director</td>
							</tr>
							<tr>
							  <td>Mr. Shivam Kumar</td>
							  <td width={280}>Non-Executive-Independent Director</td>
							</tr>
							
							<tr>
							  <td>Mr. Jayesh Seth</td>
							  <td width={280}>CFO</td>
							</tr>
							<tr>
							  <td>Ms. Priyanka</td>
							  <td width={280}>Company Secretary</td>
							</tr>
						  </tbody>
						</table>
					  </div>
					</aside>
					{/*row*/}
				  </div>
				{/*row*/}
			  {/*col-lg-9*/}
			</div>

		</div>
	  </div>
	  {/* About End */}
	</div>
    );
}